@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  overflow-x: hidden;
}
html {
  font-size: 62.5%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

span.text-primary,
a.text-primary,
h2.text-primary,
h3.text-primary {
  color: #9579df !important;
}
.text-decoration-none:hover {
  text-decoration: underline !important;
}
.navbar-nav {
  display: flex;
  width: 100%;
}
.navbar-nav a {
  font-size: 16px;
  color: #000 !important;
  flex: 1;
}

.menuLink {
  padding: 20px 5px 5px !important;
  text-decoration: none;
}

.btn-link {
  height: 34px;
}

.menuLink:hover,
.menuLink.active {
  border-radius: 0 0 10px 10px;
  background: linear-gradient(90deg, #9579df 0%, rgba(255, 255, 255, 0) 421.5%);
  color: #fff !important;
}

.navbar-brand {
  padding-top: 26px;
}
.navbar-brand img {
  margin-top: 26px;
}
.footerNavBar a:hover {
  color: #9579df !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section-2,
.section-talent {
  background: url(img/Jones/sectionpurple.png) 100% 0 no-repeat;
}
.section-3 {
  background: url(img/Jones/section_shape_2.svg) 0 0 / cover no-repeat;
}
.section-4 {
  background: url(img/Jones/section_shape_311.svg);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 50px !important;
}
.section-5 {
  background: url(img/Jones/contactsection.jpg) 0 0 / cover no-repeat;
}
.sectionMainHeading {
  font-size: 50px !important;
  font-weight: 600;
  line-height: 67px;
  color: #9579df;
  margin-top: 12px;
  margin-bottom: 0;
}
.sectionHeading {
  font-size: 3rem !important;
}
.packageCardPrice {
  font-size: 40px !important;
  font-weight: 700 !important;
  line-height: 53px !important;
  color: #9579df;
  padding: 0 !important;
}
.sectionParaHeading {
  font-style: normal;
  font-weight: 400;
  font-size: 40px !important;
  line-height: 53px;
  text-align: center;
  color: #9579df;
}
.article-heading {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 33px;
  color: #212529;
  text-align: left;
}
.article-body {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  color: #425563;
  margin-top: 15px;
  margin-bottom: 0;
}
.article-more {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #9579df;
  margin-top: 15px;
  text-decoration: unset;
  float: left;
}
.fs-20 {
  font-size: 2rem;
}
.fs-16 {
  font-size: 1.6rem;
}
p {
  font-size: 1.8rem;
}
div.border {
  border: 1px dotted #9579df !important;
}

.bgThemeColor {
  background-color: rgba(149, 121, 223, 1);
}
.lh-20 {
  line-height: 20px;
}
.rounded-pill,
.form-control,
button.btn-primary {
  border-radius: 30px !important;
}
.btn-outline-primary,
.form-control {
  border-color: #9579df !important;
}
.navbar-nav a.bg-primary {
  background: linear-gradient(
    90deg,
    #9579df 0%,
    rgba(255, 255, 255, 0) 421.5%
  ) !important;
  color: #fff !important;
  border-radius: 30px !important;
}
button.btn-primary {
  height: 45px;
  min-width: 200px;
  border-color: transparent;
  background: linear-gradient(
    90deg,
    #9579df 0%,
    rgba(255, 255, 255, 0) 421.5%
  ) !important;
}
@media (min-width: 1200px) {
  /* For mobile phones: */
  .fs-12 {
    font-size: 12px;
    line-height: 18px;
  }
  .f-80 {
    font-size: 5rem !important;
    line-height: 100px !important;
  }
  h2 {
    font-size: 3rem !important;
  }
  h4 {
    font-size: 2rem !important;
  }
  h5 {
    font-size: 1.5rem !important;
  }
  .fs-10 {
    font-size: 1rem;
  }
  .f-20 {
    font-size: 1.25rem !important;
  }
  .fs-30 {
    font-size: 3rem !important;
  }
  .f-36 {
    font-size: 2.25rem !important;
  }
  .f-32 {
    font-size: 2rem !important;
  }
  .f-40 {
    font-size: 2.5rem !important;
  }
  .f-28 {
    font-size: 1.75rem !important;
  }
  .f-22 {
    font-size: 1.375rem !important;
  }
  .section-4 {
    min-height: 543px;
  }
  .section-5 {
    min-height: 603px;
  }
}

@media (max-width: 980px) {
  .col {
    margin-bottom: 1rem;
  }
}
.bannerArea {
  background: rgba(250, 248, 255, 1);
  border-top: 1px dotted #9579df;
  border-bottom: 1px dotted #9579df;
}

.starImg {
  background: url(./img/Jones/star_banner11.png) 0 0 / 100% no-repeat;
}

.talentContantArea {
  background: url(./img/Jones/talent_section_bg.svg) 0 50% / 100% no-repeat;
}
.featuresContantArea {
  background: url(./img/Jones/features_bg.svg) 0 0 no-repeat;
}
.blogContantArea {
  background: url(./img/Jones/blog_bg.svg) 0 0 no-repeat;
}
.zigZagImg {
  min-height: 350px;
}

.arrow-left {
  position: relative;
  background: #faf8ff;
  border: 1px dotted #9579df;
  margin-left: 60px;
}
.arrow_box:after,
.arrow_box:before {
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-left:after {
  right: 100%;
  border-color: rgba(249, 246, 242, 0);
  border-right-color: #faf8ff;
  border-width: 54px;
  margin-top: -54px;
}
.arrow-left:before {
  right: 100%;
  border-color: rgba(200, 168, 125, 0);
  border-right-color: #9579df;
  border-width: 54px;
  margin-top: -54px;
}

.arrow_right {
  position: relative;
  background: #faf8ff;
  border: 1px dotted #9579df;
  margin-right: 60px;
}
.arrow_right:after {
  left: 100%;
  border-color: rgba(249, 246, 242, 0);
  border-left-color: #faf8ff;
  border-width: 54px;
  margin-top: -54px;
}
.arrow_right:before {
  left: 100%;
  border-color: rgba(200, 168, 125, 0);
  border-left-color: #9579df;
  border-width: 54px;
  margin-top: -54px;
}

.timeLine::after {
  position: absolute;
  height: 100%;
  content: "";
  width: 3px;
  background-color: #9579df;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.rightColumn,
.leftColumn {
  position: relative;
}
.rightColumn::before,
.leftColumn::after {
  content: "";
  height: 25px;
  width: 25px;
  background-color: #9579df;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.rightColumn::before {
  left: -15px;
}
.leftColumn::after {
  right: -15px;
}

@media (max-width: 600px) {
  .countNumber {
    font-size: 34px !important;
    min-height: 280px !important;
    padding: 0 50px !important;
  }
}

@media (max-width: 450px) {
  .countNumber {
    font-size: 26px !important;
    min-height: 200px !important;
  }
}
.countNumber {
  font-size: 49px;
}

.list {
  font-size: 20px;
}

.brand {
  color: #9579df;
}

.package {
  color: #9579df;
}

.client-section {
  height: 200px !important;
}

.package-image {
  height: 250px !important;
}

.press-image {
  height: 300px !important;
}

.p-home {
  font-size: "5px";
}

.card-bg {
  background-color: "#9579df" !important;
}
.abc {
  margin-left: "-70rem" !important ;
}
.press-release {
  font-weight: 600;
  font-size: 40px;
  margin-left: 20px;
  color: #9579df;
}

.press-release-section {
  background: url(./img/Jones/star_banner11.png) 0 0 no-repeat;
}

.card-button {
  width: 100%;
  height: 45px;
  color: #9579df;
  background: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid #9579df;
  border-radius: 100px;
  margin-top: 22px;
}

.valuable {
  /* background: url(./img/Jones/rectangle.png) 0 0 no-repeat; */
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 25px !important;
  background-color: #977BDF;
  color: #ffffff;
  width: 390px !important;
  border-radius: 30px;
  margin: 20px;
  @media (max-width: 420px){
    margin: 0;
  }
}

.btn-float-right{
  float: right;
}

.valuable p {
  text-align: left;
}

.publication-section {
  background: url(./img/Jones/section_shape_311.png) 0 0 / cover no-repeat;
  background-position: center;
}
.pressrelease-section {
  background: url(./img/Jones/bgstars21.png) 0 0 no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 29px;
}
.float-layout {
  padding: 5px 5px;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0;
}

.card-container {
  overflow: hidden;
}

.card {
  background-color: dodgerblue;
  color: black;
  min-height: 100%;
  width: 100%;
  width: 50%;
  float: right;
}

.card-title {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  padding-top: 20px;
}

.card-desc {
  padding: 10px;
  text-align: left;
  font-size: 18px;
}

.card-image {
  display: flex;
}

div.card-image img {
  width: 50%;
  height: auto;
}
.para-ceo {
  padding-top: 100px;
}
.photo-ceo {
  margin-bottom: 80px;
}
.ceo-icons {
  margin-left: 280px;
}
.publications {
  margin-top: 170px !important;
}

.bannerContainer {
  background-image: url("./img/Jones/bg1.png");
}

.countNumber {
  width: fit-content;
  padding: 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./img/Jones/bg2.png");
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 368px;
}
.mobile-testimonial {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 27.9px !important;
}

.testimonial-mobile {
  position: relative;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #212529;
}

.testimonial-quote {
  position: absolute;
  left: -15px;
  top: -20px;
}

.testimonial-avatar {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .testimonial-avatar {
    max-width: 275px;
    margin-top: -100px;
  }

  .sectionMainHeading {
    font-size: 35px !important;
    line-height: 47px !important;
  }
  .blog-1 {
    height: 250px !important;
    width: 300px !important;
  }
  .blog-2 {
    height: 205px !important;
    width: 300px !important;
  }
  .blog-3 {
    height: 250px !important;
    width: 300px !important;
  }
  .blog-4 {
    height: 250px !important;
    width: 300px !important;
  }
  .blog-5 {
    height: 250px !important;
    width: 300px !important;
  }
}
@media only screen and (max-width: 480px) {
  .testimonial-mobile {
    margin-left: 100px;
  }
  .mobile-testimonial {
    margin-left: -10px !important;
  }
  .arrow {
    margin: auto;
  }
  .vr-mobile {
    height: 100%;
    width: 100px;
  }
  .blog-row {
    margin-left: -100px;
  }
  .ceo-icons {
    margin-left: 150px;
  }
  .photo-ceo {
    margin-left: 30px;
  }
  .para-ceo {
    margin-left: 50px;
    padding-top: 20px;
  }
  .press-release {
    margin-left: 60px;
  }
  .socialseedceo {
    margin-left: 30px;
  }
  .publications {
    margin-left: 200px !important;
  }
  .blog-1 {
    height: 120px !important;
    width: 200px !important;
  }
  .blog-2 {
    height: 120px !important;
    width: 200px !important;
  }
  .blog-3 {
    height: 120px !important;
    width: 200px !important;
  }
  .blog-4 {
    height: 120px !important;
    width: 200px !important;
  }
  .blog-5 {
    height: 120px !important;
    width: 200px !important;
  }
}
